// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgMedal = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.648 1.308a.75.75 0 0 0-.98.404l-1.04 2.5a.75.75 0 0 0 1.384.576l1.04-2.5a.75.75 0 0 0-.404-.98m3.655.006a.75.75 0 0 0-.99.383l-3.449 7.814a7 7 0 0 0-.6-.143L9.51 1.671a.75.75 0 0 0-1.348.658l3.384 6.936a6.7 6.7 0 0 0-1.875.398l-4-7.998a.75.75 0 0 0-1.341.67l4 8a6.75 6.75 0 1 0 6.922-.252l3.435-7.78a.75.75 0 0 0-.383-.99m-5.633 9.478a5.25 5.25 0 1 1-1.342 10.416 5.25 5.25 0 0 1 1.342-10.416"
    />
  </svg>
);
export const MedalIcon = forwardRef(SvgMedal);
