// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgCreate = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.244 10.077a.833.833 0 0 1 1.423.59V16.5a4.17 4.17 0 0 1-4.167 4.167H8.167A4.17 4.17 0 0 1 4 16.5V8.167A4.17 4.17 0 0 1 8.167 4H14a.833.833 0 0 1 0 1.667H8.167a2.5 2.5 0 0 0-2.5 2.5V16.5a2.5 2.5 0 0 0 2.5 2.5H16.5a2.5 2.5 0 0 0 2.5-2.5v-5.833c0-.221.088-.433.244-.59m-8.535 2.826.797-2.39a.8.8 0 0 1 .201-.325l5.614-5.615a1.964 1.964 0 0 1 2.771 0 1.96 1.96 0 0 1 0 2.773l-5.613 5.613a.84.84 0 0 1-.325.201l-2.39.797a.838.838 0 0 1-1.055-1.054"
      clipRule="evenodd"
    />
  </svg>
);
export const CreateIcon = forwardRef(SvgCreate);
